const AppleIcon = ({ ...rest }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.011 3.18979C13.3979 2.7541 13.693 2.24802 13.8792 1.70062C14.0653 1.15323 14.139 0.57529 14.0959 0C12.908 0.0936807 11.8053 0.639514 11.0254 1.5199C10.6519 1.94185 10.3689 2.43272 10.1931 2.96345C10.0173 3.49418 9.95224 4.05397 10.0019 4.60969C10.5813 4.61441 11.1541 4.4888 11.6759 4.24258C12.1977 3.99636 12.6545 3.63613 13.011 3.18979V3.18979ZM15.5903 10.6293C15.5971 9.86308 15.8037 9.11121 16.1905 8.44464C16.5773 7.77807 17.1316 7.21878 17.8011 6.81955C17.3786 6.22483 16.8201 5.73445 16.1698 5.38732C15.5196 5.04018 14.7955 4.84583 14.055 4.81968C12.4583 4.65969 10.9844 5.72962 10.1349 5.72962C9.2854 5.72962 8.08788 4.83968 6.75731 4.85968C5.88745 4.88767 5.03987 5.13544 4.29723 5.57882C3.55459 6.02219 2.94225 6.64604 2.51994 7.38951C0.718552 10.4493 2.05936 14.999 3.86075 17.4688C4.67957 18.6788 5.70308 20.0487 7.05413 19.9987C8.40517 19.9487 8.84528 19.1787 10.4113 19.1787C11.9772 19.1787 12.4583 19.9987 13.7889 19.9687C15.1194 19.9387 16.0611 18.7288 16.9208 17.5188C17.5299 16.6404 18.0056 15.6805 18.3333 14.669C17.5221 14.3311 16.8301 13.7684 16.3426 13.0504C15.8551 12.3325 15.5936 11.4908 15.5903 10.6293V10.6293Z"
        fill="black"
      />
    </svg>
  );
};

export default AppleIcon;

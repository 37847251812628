import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./api/apiClient";

//constants
import { GOOGLE_WEB_CLIENT_ID } from "./constants";

//pages
import { LoginPage } from "./pages";

//styles
import "./styles/styles.scss";

function App() {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_WEB_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        <LoginPage />
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
}

export default App;

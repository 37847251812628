import clsx from "clsx";

//assets
import { ButtonLoaderIcon } from "../../assets/icons";

//types
import { IButtonProps } from "../components.types";

//styles
import styles from "./Button.module.scss";

const Button = ({
  type = "gradient",
  text,
  disable,
  className,
  icon,
  loading,
  handleClick,
}: IButtonProps) => {
  const handleButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    !disable && !loading && !!handleClick && handleClick();
  };

  return (
    <button
      disabled={disable || loading}
      type="submit"
      onClick={(e) => handleButton(e)}
      className={clsx(
        styles.button,
        {
          [styles.gradientButton]: type === "gradient",
          [styles.grayButton]: type === "gray",
          [styles.whiteButton]: type === "white",
          [styles.disable]: disable,
        },
        className
      )}
    >
      {loading ? (
        <div className={styles.button__loader}>
          <ButtonLoaderIcon color={type === "gradient" ? "white" : "black"} />
        </div>
      ) : (
        <>
          {icon}
          {text}
        </>
      )}
    </button>
  );
};

export default Button;

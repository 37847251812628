import { useMutation } from "react-query";
import { authApple, authGoogle, getGoogleIdToken, getMe } from "../services";

const sendMessage = async (tokens: any, candidate: any) => {
  window.postMessage(
    {
      authData: {
        tokens,
        candidate,
      },
    },
    "https://ext.auth.karizma.ai"
  );
  setTimeout(() => window.close(), 100);
};

export const useGoogleAuth = () => {
  return useMutation(getGoogleIdToken, {
    onSuccess: async (resp) => {
      let authResponse = await authGoogle(resp.id_token);
      let candidateResponse = await getMe(authResponse.tokens.accessToken);

      if (!!authResponse?.tokens && !!candidateResponse?.candidate) {
        await sendMessage(authResponse.tokens, candidateResponse?.candidate);
      }
    },
  });
};

export const useAppleAuth = () => {
  return useMutation(authApple, {
    onSuccess: async (resp) => {
      console.log("authApple --> resp", resp);
      let candidateResponse = await getMe(resp.tokens.accessToken);

      if (!!resp.tokens && !!candidateResponse.candidate) {
        await sendMessage(resp.tokens, candidateResponse.candidate);
      }
    },
  });
};

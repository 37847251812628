import clsx from "clsx";
import AppleLogin from "react-apple-login";

//components
import { Button } from "../../components";

//assets
import { logoIcon } from "../../assets/images";
import { AppleIcon, GoogleIcon } from "../../assets/icons";

//hooks
import { useLoginPage } from "./useLoginPage";

//styles
import styles from "./LoginPage.module.scss";

const LoginPage = () => {
  const {
    googleAuthError,
    appleAuthError,
    showGoogleButtonLoader,
    showAppleButtonLoader,
    handleGoogleLogin,
    handleAppleResponse,
    handleOpenPrivacyPolicy,
    handleOpenTermsOfService,
  } = useLoginPage();

  return (
    <div className={styles["login"]}>
      <div className={styles["login__content"]}>
        <img src={logoIcon} className={styles["login__logo"]} />

        <div
          className={clsx(
            styles["login__button"],
            styles["login__apple--button"]
          )}
        >
          <Button
            handleClick={handleGoogleLogin}
            loading={showGoogleButtonLoader}
            icon={<GoogleIcon />}
            type="white"
            text="Log In with Google"
            className={styles.login__button}
          />
          {!!googleAuthError?.response?.data?.message && (
            <div className={styles["login__error"]}>
              {googleAuthError?.response?.data?.message}
            </div>
          )}
        </div>

        <AppleLogin
          clientId={"com.karizma-employer-dashboard"}
          redirectURI="https://ext.auth.karizma.ai"
          usePopup={true}
          responseType="id_token"
          callback={handleAppleResponse}
          scope="email name"
          responseMode="query"
          render={(renderProps) => (
            <div className={styles["login__button"]}>
              <Button
                loading={showAppleButtonLoader}
                handleClick={renderProps.onClick}
                icon={<AppleIcon />}
                type="white"
                text="Log In with Apple"
              />
              {!!appleAuthError?.response?.data?.message && (
                <div className={styles["login__error"]}>
                  {appleAuthError?.response?.data?.message}
                </div>
              )}
            </div>
          )}
        />

        <div className={styles["login__privacy"]}>
          <p onClick={handleOpenPrivacyPolicy}>Privacy Policy</p>
          <div className={styles["login__privacy--dot"]} />
          <p onClick={handleOpenTermsOfService}>Terms of Services</p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

import { useEffect } from "react";
import { AxiosError } from "axios";

import { useGoogleLogin } from "@react-oauth/google";

//requets
import { useAppleAuth, useGoogleAuth } from "../../api/requests";
import { useBoolean } from "../../hooks";

export interface IAxiosError {
  message: string;
  field?: string;
}

export const useLoginPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const googleCode = urlParams.get("code");

  const {
    value: showGoogleButtonLoader,
    setTrue: onShowGoogleButtonLoader,
    setFalse: onHideGoogleButtonLoader,
  } = useBoolean(false);

  const {
    value: showAppleButtonLoader,
    setTrue: onShowAppleButtonLoader,
    setFalse: onHideAppleButtonLoader,
  } = useBoolean(false);

  const { mutate: googleAuth, error: googleAuthError } = useGoogleAuth();
  const { mutate: appleAuth, error: appleAuthError } = useAppleAuth();

  const handleGoogleLogin = useGoogleLogin({
    flow: "auth-code",
    redirect_uri: "https://ext.auth.karizma.ai",
    scope: "email openid profile",
    ux_mode: "redirect",
  });

  const handleAppleResponse = (value: any) => {
    console.log("handleAppleResponse --> value", value);

    if (!!value?.authorization.id_token) {
      onShowAppleButtonLoader();
      appleAuth(value?.authorization.id_token, {
        onError: onHideAppleButtonLoader,
      });
    }
  };

  const handleOpenPrivacyPolicy = () =>
    window.open("https://www.karizma.ai/privacy", "_blank");
  const handleOpenTermsOfService = () =>
    window.open("https://www.karizma.ai/termsofservice", "_blank");

  useEffect(() => {
    if (!!googleCode) {
      onShowGoogleButtonLoader();
      googleAuth(googleCode, { onError: onHideGoogleButtonLoader });
    }
  }, [googleCode]);

  return {
    googleAuthError: googleAuthError as AxiosError<IAxiosError>,
    appleAuthError: appleAuthError as AxiosError<IAxiosError>,
    showGoogleButtonLoader,
    showAppleButtonLoader,
    handleGoogleLogin,
    handleAppleResponse,
    handleOpenPrivacyPolicy,
    handleOpenTermsOfService,
  };
};

const GoogleIcon = ({ ...rest }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.2509 10.1941C19.2509 9.47471 19.1913 8.94971 19.0624 8.40527H10.6794V11.6525H15.6C15.5009 12.4594 14.9652 13.6747 13.7747 14.4913L13.758 14.6L16.4085 16.6123L16.5921 16.6303C18.2786 15.1039 19.2509 12.858 19.2509 10.1941Z"
        fill="#4285F4"
      />
      <path
        d="M10.6789 18.75C13.0896 18.75 15.1134 17.9722 16.5916 16.6305L13.7741 14.4916C13.0202 15.0068 12.0083 15.3666 10.6789 15.3666C8.31785 15.3666 6.31391 13.8402 5.59956 11.7305L5.49485 11.7392L2.7388 13.8295L2.70276 13.9277C4.17099 16.786 7.18686 18.75 10.6789 18.75Z"
        fill="#34A853"
      />
      <path
        d="M5.60002 11.7302C5.41153 11.1858 5.30245 10.6024 5.30245 9.99967C5.30245 9.39686 5.41153 8.81355 5.5901 8.26911L5.58511 8.15316L2.79451 6.0293L2.70321 6.07186C2.09808 7.25798 1.75085 8.58995 1.75085 9.99967C1.75085 11.4094 2.09808 12.7413 2.70321 13.9274L5.60002 11.7302Z"
        fill="#FBBC05"
      />
      <path
        d="M10.679 4.63331C12.3555 4.63331 13.4865 5.34303 14.1313 5.93613L16.6512 3.525C15.1036 2.11528 13.0897 1.25 10.679 1.25C7.18689 1.25 4.171 3.21387 2.70276 6.07218L5.58966 8.26944C6.31393 6.15972 8.31789 4.63331 10.679 4.63331Z"
        fill="#EB4335"
      />
    </svg>
  );
};

export default GoogleIcon;

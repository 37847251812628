import axios from "axios";
import { apiClient } from "../apiClient";

export const getGoogleIdToken = async (code: string) => {
  const body = {
    code,
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
    grant_type: "authorization_code",
    redirect_uri: "https://ext.auth.karizma.ai",
    // redirect_uri: "http://localhost:3000",
  };

  const { data } = await axios.post(
    "https://oauth2.googleapis.com/token",
    body
  );
  return data;
};

export const authGoogle = async (token: string) => {
  const { data } = await apiClient.post("auth/google", {
    token,
    type: "candidate",
  });
  return data;
};

export const authApple = async (token: string) => {
  const { data } = await apiClient.post("auth/apple", {
    token,
    clientId: "com.karizma-employer-dashboard",
    type: "candidate",
  });

  return data;
};

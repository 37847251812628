import { apiClient } from "../apiClient";

export const getMe = async (accessToken: string) => {
  const { data } = await apiClient.get("/candidate/my", {
    headers: {
      "Content-Type": "application/json",
      "karizma-agent": "karizma_candidate_ext",
      karizmaVersion: 2,
      authorization: `Bearer ${accessToken}`,
    },
  });
  return data;
};
